import { Button, ButtonType, Grid, Heading, Icon, Size, Stack } from '@vaisala/rockhopper-components';
import { VaiIcon } from '@vaisala/rockhopper-design-tokens';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CSA_DASHBOARD_LINK,
  CSA_RELEASE_NOTE_LINK,
  LS_CONTRACT_EXPIRY,
  LS_CONTRACT_ID,
  SOFTWARE_ASSURANCE_CV_COMPONENT_ID,
  SOFTWARE_ASSURANCE_STATUS,
  SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS,
  SOFTWARE_ASSURANCE_STATUS_NAME
} from '../../../constants';
import { TranslationKey } from '../../../react-i18next';
import { AtlassianStatusApiResponse } from '../../../store/services/atlassianStatusApi';
import { TEST_IDS } from '../../../tests/testids';
import { getConstantFromLocalStorage, getFormattedTimeFromISOString, timestampToTimeString } from '../../../utils';
import { EmptyState } from '../../Utils/EmptyState/EmptyState';
import Infotip from '../../Utils/Infotip';
import { useGetVersionQuery } from '../../../store/services/versionApi';
import { StatusIcons } from '../StatusIcons';
import useUserSettings from '../../../hooks/useUserSettings';

export const SoftwareStatusNameTranslationKeyMap: Record<string, TranslationKey> = {
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.DATAINTEGRITY]: 'csa.dataAcquisitionAndIntegrity',
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.ALARMS]: 'csa.alarms',
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.NOTIFICATIONS]: 'csa.notifications',
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.REPORTS]: 'csa.reports',
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.ACCESSCONTROL]: 'csa.accessControl'
};

export const SoftwareStatusInfotipsMap: Record<
  string,
  { translationKey: TranslationKey; infoIconTestId: string; infoContentTestId: string }
> = {
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.DATAINTEGRITY]: {
    translationKey: 'csa.infotips.dataAcquisitionAndIntegrity',
    infoIconTestId: TEST_IDS.sidebar_csa_infotip_data_aquisation_and_integrity,
    infoContentTestId: TEST_IDS.sidebar_csa_infotip_data_aquisation_and_integrity_content
  },
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.ALARMS]: {
    translationKey: 'csa.infotips.alarms',
    infoIconTestId: TEST_IDS.sidebar_csa_infotip_alarms,
    infoContentTestId: TEST_IDS.sidebar_csa_infotip_alarms_content
  },
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.NOTIFICATIONS]: {
    translationKey: 'csa.infotips.notifications',
    infoIconTestId: TEST_IDS.sidebar_csa_infotip_notifications,
    infoContentTestId: TEST_IDS.sidebar_csa_infotip_notifications_content
  },
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.REPORTS]: {
    translationKey: 'csa.infotips.reports',
    infoIconTestId: TEST_IDS.sidebar_csa_infotip_reports,
    infoContentTestId: TEST_IDS.sidebar_csa_infotip_reports_content
  },
  [SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS.ACCESSCONTROL]: {
    translationKey: 'csa.infotips.accessControl',
    infoIconTestId: TEST_IDS.sidebar_csa_infotip_access_control,
    infoContentTestId: TEST_IDS.sidebar_csa_infotip_access_control_content
  }
};

export const SoftwareStatusTranslationKeyMap: Record<SOFTWARE_ASSURANCE_STATUS, TranslationKey> = {
  [SOFTWARE_ASSURANCE_STATUS.OK]: 'csa.ok',
  [SOFTWARE_ASSURANCE_STATUS.ISSUEDETECTED]: 'csa.issueDetected',
  [SOFTWARE_ASSURANCE_STATUS.ISSUECONFIRMED]: 'csa.issueConfirmed'
};

type SoftwareAssuranceProps = {
  statusResult: SOFTWARE_ASSURANCE_STATUS;
  status: AtlassianStatusApiResponse | undefined;
};

const SoftwareAssurance: React.FC<SoftwareAssuranceProps> = ({ statusResult, status }) => {
  const { t } = useTranslation();
  const { timezone } = useUserSettings();
  const { data: version, isLoading: versionIsLoading } = useGetVersionQuery();

  const [isCSADetailsVisible, setIsCSADetailsVisible] = React.useState(false);
  const contractId = getConstantFromLocalStorage(LS_CONTRACT_ID) ?? '';
  const contractExpiry = getConstantFromLocalStorage(LS_CONTRACT_EXPIRY) ?? '';

  const showCSADetails = () => {
    setIsCSADetailsVisible(!isCSADetailsVisible);
  };

  if (!status) return <EmptyState heading="noResults" illustration="nothingFound" />;

  const filteredComponents = status.components.filter(component =>
    Object.values(SOFTWARE_ASSURANCE_STATUS_COMPONENT_IDS).includes(component.id as SOFTWARE_ASSURANCE_STATUS_NAME)
  );

  const ResultIcon = StatusIcons[statusResult];

  const getCVComponentUpdateTime = (componentId: string) => {
    const CVDescription = status.components.find(component => component.id === componentId)?.description;

    if (CVDescription) {
      return getFormattedTimeFromISOString(CVDescription, timezone);
    }
  };
  const updatedTime = getCVComponentUpdateTime(SOFTWARE_ASSURANCE_CV_COMPONENT_ID);

  const goToDashboard = () => {
    window.open(CSA_DASHBOARD_LINK, '_blank');
  };
  const goToReleaseNote = () => {
    window.open(CSA_RELEASE_NOTE_LINK, '_blank');
  };

  const versionRows = [
    {
      id: 'version-row-0',
      label: t('csa.version'),
      value: version && !versionIsLoading && (
        <div data-ta={`${TEST_IDS.sidebar_csa_version}_version`}>{version.version}</div>
      ),
      dataTa: TEST_IDS.sidebar_csa_version
    },
    {
      id: 'version-row-1',
      label: t('csa.released'),
      value: version && !versionIsLoading && timestampToTimeString(version.timestamp),
      dataTa: TEST_IDS.sidebar_csa_released
    },
    {
      id: 'version-row-2',
      label: t('csa.contractId'),
      value: contractId,
      dataTa: TEST_IDS.sidebar_csa_contract_id
    },
    {
      id: 'version-row-3',
      label: t('csa.renewalDate'),
      value: contractExpiry,
      dataTa: TEST_IDS.sidebar_csa_renewal_date
    }
  ];

  return (
    <Stack dataTa={TEST_IDS.sidebar_csa_stack} className="csa-stack" spacing={Size.M}>
      <Heading dataTa={TEST_IDS.sidebar_csa_stack_heading} level={2}>
        {t('csa.title')}
      </Heading>
      <Grid padding={Size.L} className="csa-grid">
        <Grid.Row className="vai-margin-bottom-s">
          <Grid.Col xl={6} lg={6} md={6} sm={6} xs={6}>
            {t('csa.latestTest')}
          </Grid.Col>
          <Grid.Col
            dataTa={TEST_IDS.sidebar_csa_update_time}
            className="updated-time"
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
          >
            {updatedTime}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="vai-margin-bottom-s">
          <Grid.Col dataTa={TEST_IDS.sidebar_csa_result_title} xl={6} lg={6} md={6} sm={6} xs={6}>
            {t('csa.results')}
          </Grid.Col>
          <Grid.Col dataTa={TEST_IDS.sidebar_csa_result_name} xl={6} lg={6} md={6} sm={6} xs={6}>
            {t(SoftwareStatusTranslationKeyMap[statusResult])}
            <ResultIcon className="vai-margin-left-s" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Button
            className="csa-button"
            dataTa={TEST_IDS.sidebar_csa_view_result_button}
            onClick={showCSADetails}
            buttonType={ButtonType.Link}
            startIcon={<Icon name={isCSADetailsVisible ? VaiIcon.ChevronUp : VaiIcon.ChevronDown} />}
          >
            {t(isCSADetailsVisible ? 'csa.hideDetails' : 'csa.viewDetails')}
          </Button>
        </Grid.Row>
      </Grid>
      {isCSADetailsVisible && (
        <Grid dataTa={TEST_IDS.sidebar_csa_details_grid} padding={Size.L} className="csa-grid">
          {filteredComponents.map(({ id, status }) => {
            const StatusIcon = StatusIcons[status as SOFTWARE_ASSURANCE_STATUS];
            const infotipData = SoftwareStatusInfotipsMap[id as SOFTWARE_ASSURANCE_STATUS_NAME];
            return (
              <Grid.Row key={id} className="vai-margin-bottom-s">
                <Grid.Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  {t(SoftwareStatusNameTranslationKeyMap[id as SOFTWARE_ASSURANCE_STATUS_NAME])}
                </Grid.Col>
                <Grid.Col xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Infotip
                    popoverProps={{ placement: 'bottom-end', dataTa: infotipData.infoContentTestId }}
                    iconProps={{ dataTa: infotipData.infoIconTestId }}
                  >
                    {t(infotipData.translationKey)}
                  </Infotip>
                </Grid.Col>
                <Grid.Col xl={4} lg={4} md={4} sm={4} xs={4}>
                  {t(SoftwareStatusTranslationKeyMap[status as SOFTWARE_ASSURANCE_STATUS])}
                  <StatusIcon className="vai-margin-left-s" />
                </Grid.Col>
              </Grid.Row>
            );
          })}
          <Grid.Row>
            <Button
              className="csa-button"
              dataTa={TEST_IDS.sidebar_csa_view_dashboard_button}
              onClick={goToDashboard}
              buttonType={ButtonType.Link}
              startIcon={<Icon name={VaiIcon.LinkNewWindow} />}
            >
              {t('csa.viewDashboard')}
            </Button>
          </Grid.Row>
        </Grid>
      )}
      <Heading dataTa={TEST_IDS.sidebar_csa_version_notes_heading} level={2} className="csa-version__heading">
        {t('csa.versionNotes')}
      </Heading>
      <Grid dataTa={TEST_IDS.sidebar_csa_software_version_grid} padding={Size.L} className="csa-grid">
        {versionRows.map(row => (
          <Grid.Row dataTa={row.dataTa} key={row.id} className="vai-margin-bottom-s">
            <Grid.Col dataTa={`${row.dataTa}_label`} xl={6} lg={6} md={6} sm={6} xs={6}>
              {row.label}
            </Grid.Col>
            <Grid.Col dataTa={`${row.dataTa}_value`} xl={6} lg={6} md={6} sm={6} xs={6}>
              {row.value}
            </Grid.Col>
          </Grid.Row>
        ))}
        <Grid.Row>
          <Button
            className="csa-button"
            buttonType={ButtonType.Link}
            onClick={goToReleaseNote}
            dataTa={TEST_IDS.sidebar_csa_view_release_notes_button}
          >
            {t('csa.viewReleaseNotes')}
          </Button>
        </Grid.Row>
      </Grid>
    </Stack>
  );
};

export default SoftwareAssurance;
