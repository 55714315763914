import * as React from 'react';
import {
  Size,
  Button,
  ButtonType,
  Modal,
  Heading,
  Flex,
  InputField,
  Icon,
  Grid,
  Form,
  Select,
  Checkbox
} from '@vaisala/rockhopper-components';
import { VaiColor, VaiIcon } from '@vaisala/rockhopper-design-tokens';
import { useTranslation } from 'react-i18next';

import './generate-report-dialog.scss';
import { StoreState } from '../../../store';
import { connect } from 'react-redux';
import { INCLUDED_REPORT_SECTIONS, REPORT_FORMATS } from '../Locations';
import { addMinutes, differenceInMinutes, format, isBefore } from 'date-fns';
import { LS_COMPANY_CUSTOMER_ID, MAX_ESTIMATED_PAGES_ALLOWED, MAX_LOCATIONS_SELECTED } from '../../../constants';
import { isEqual } from 'lodash';
import { useGenerateReportMutation } from '../../../store/services/reportsApi';
import { convertLocalTimeToUTC, getConstantFromLocalStorage, getDateInTimezone } from '../../../utils';
import CenteredSpinner from '../../BaseComponents/CenteredSpinner';
import useUserSettings from '../../../hooks/useUserSettings';
import { TEST_IDS } from '../../../tests/testids';

type GenerateReportDialogProps = {
  isVisible: boolean;
  onDismiss: () => void;
  showConfirmation: () => void;
} & ReturnType<typeof mapStateToProps>;

const MAX_TITLE_CHARACTERS = 256;

const GenerateReportDialog = (props: GenerateReportDialogProps): JSX.Element => {
  const { t } = useTranslation();
  const [generateReportAPI, { isLoading }] = useGenerateReportMutation();
  const customerId = getConstantFromLocalStorage(LS_COMPANY_CUSTOMER_ID) ?? '';

  // "꞉" is a unicode character called a Modifier Letter Colon. This has no space like the fullwidth colon but it works in Windows filenames. The code is U+A789
  const titleFormatter = (dateStr: string) => {
    return `viewLinc ${dateStr.toString().replace(/:/g, '\uA789')}`;
  };
  const { timezone } = useUserSettings();

  const [title, setTitle] = React.useState(titleFormatter(format(getDateInTimezone(timezone), 'yyyy-MM-dd HH:mm:ss')));
  const [titleError, setTitleError] = React.useState('');
  const [formatType, setFormatType] = React.useState('');
  const [notifyByEmail, setNotifyByEmail] = React.useState(true);
  const [showAboutContent, setShowAboutContent] = React.useState(false);

  const [includedSections, setIncludedSections] = React.useState<
    { [key in INCLUDED_REPORT_SECTIONS]: { checked: boolean; label: string; disabled: boolean } }
  >({
    [INCLUDED_REPORT_SECTIONS.LOCATIONS_SUMMARY]: {
      checked: true,
      disabled: false,
      label: t('reports.locationsSummaryTable')
    },
    [INCLUDED_REPORT_SECTIONS.GRAPHS_BY_THRESHOLD]: {
      checked: true,
      disabled: false,
      label: t('reports.graphsByThreshold')
    },
    [INCLUDED_REPORT_SECTIONS.STATISTICS_SUMMARY]: {
      checked: true,
      disabled: false,
      label: t('reports.statisticsSummary')
    },
    [INCLUDED_REPORT_SECTIONS.ALARMS]: {
      checked: true,
      disabled: false,
      label: t('reports.alarms')
    },
    [INCLUDED_REPORT_SECTIONS.DATA_TABLE]: {
      checked: false,
      disabled: false,
      label: t('reports.dataTable')
    }
  });

  // Data table not incuded by default
  const [includedSectionsCount, setIncludedSectionsCount] = React.useState(Object.keys(includedSections).length - 1);
  const [dataTableInterval, setDataTableInterval] = React.useState('0');

  const [estimatedPagesCount, setEstimatedPagesCount] = React.useState(1);

  const getNumberOfMinutes = (from: Date, to: Date, interval: number) => {
    let count = 0;

    while (isBefore(from, to) || isEqual(from, to)) {
      count++;
      from = addMinutes(from, interval);
    }

    return count;
  };

  const calculateEstimatedPageCount = () => {
    let count = 1 + Math.ceil(props.selectedFormattedLocationsNum / 20); // Title Card + Reports Summary

    // Locations summary
    if (includedSections[INCLUDED_REPORT_SECTIONS.LOCATIONS_SUMMARY].checked) {
      count += Math.ceil(props.selectedFormattedLocationsNum / 6);
    }

    // Graphs by threshold
    if (includedSections[INCLUDED_REPORT_SECTIONS.GRAPHS_BY_THRESHOLD].checked) {
      count += Math.ceil(
        (Math.ceil(props.selectedFormattedLocationsNum / MAX_LOCATIONS_SELECTED) +
          props.selectedFormattedLocationsNum) /
          2
      );
    }

    // Statistics summary
    if (includedSections[INCLUDED_REPORT_SECTIONS.STATISTICS_SUMMARY].checked) {
      count += props.graphsNum * 2;
    }

    // Alarms
    if (includedSections[INCLUDED_REPORT_SECTIONS.ALARMS].checked) {
      count += Math.ceil(
        (Math.ceil(props.selectedFormattedLocationsNum / 4) + props.selectedFormattedLocationsNum) / 2
      );
    }

    // Data table
    if (includedSections[INCLUDED_REPORT_SECTIONS.DATA_TABLE].checked) {
      let dateRange;

      // Get number of minutes according to selected interval
      if (+dataTableInterval > 0) {
        dateRange = getNumberOfMinutes(props.intervalDate.from, props.intervalDate.to, +dataTableInterval);
      } else {
        dateRange = differenceInMinutes(props.intervalDate.to, props.intervalDate.from, { roundingMethod: 'ceil' });
      }

      count += Math.ceil(props.selectedFormattedLocationsNum / 8) * Math.ceil(dateRange / 50);
    }

    // Round up to the nearest multiple of 5
    if (count > 20) {
      count = Math.ceil(count / 5) * 5;
    }

    setEstimatedPagesCount(count);
  };

  React.useEffect(() => {
    calculateEstimatedPageCount();
  }, [includedSections, dataTableInterval]);

  React.useEffect(() => {
    if (formatType === REPORT_FORMATS.CSV || formatType === REPORT_FORMATS.XLSX) {
      disableGraphsAndAlarms(true);
    } else if (formatType === REPORT_FORMATS.PDF) {
      disableGraphsAndAlarms(false);
    }
  }, [formatType]);

  const intervalValues = [
    { value: '0', label: t('reports.noIntervals') },
    { value: '5', label: t('reports.minuteInterval', { count: 5 }) },
    { value: '10', label: t('reports.minuteInterval', { count: 10 }) },
    { value: '30', label: t('reports.minuteInterval', { count: 30 }) },
    { value: '60', label: t('reports.minuteInterval', { count: 60 }) }
  ];

  const aboutContent = [
    {
      title: t('reports.locationsSummaryTable'),
      description: t('reports.locationsSummaryTableDescription')
    },
    {
      title: t('reports.graphsByThreshold'),
      description: t('reports.graphsByThresholdDescription')
    },
    {
      title: t('reports.statisticsSummary'),
      description: t('reports.statisticsSummaryDescription')
    },
    {
      title: t('reports.alarms'),
      description: t('reports.alarmsDescription')
    },
    {
      title: t('reports.dataTable'),
      description: t('reports.dataTableDescription')
    }
  ];

  const disableGraphsAndAlarms = (disabled: boolean) => {
    setIncludedSections({
      ...includedSections,
      [INCLUDED_REPORT_SECTIONS.GRAPHS_BY_THRESHOLD]: {
        ...includedSections[INCLUDED_REPORT_SECTIONS.GRAPHS_BY_THRESHOLD],
        disabled,
        checked: disabled ? false : includedSections[INCLUDED_REPORT_SECTIONS.GRAPHS_BY_THRESHOLD].checked
      },
      [INCLUDED_REPORT_SECTIONS.ALARMS]: {
        ...includedSections[INCLUDED_REPORT_SECTIONS.ALARMS],
        disabled,
        checked: disabled ? false : includedSections[INCLUDED_REPORT_SECTIONS.ALARMS].checked
      }
    });
  };

  // At least one checkbox is selected
  const toggleIncludedSection = (key: INCLUDED_REPORT_SECTIONS) => {
    const prevChecked = includedSections[key].checked;

    // User unchecking it
    if (prevChecked && includedSectionsCount > 1) {
      setIncludedSections({
        ...includedSections,
        [key]: { ...includedSections[key], checked: !includedSections[key].checked }
      });

      setIncludedSectionsCount(includedSectionsCount - 1);
    } else if (!prevChecked) {
      setIncludedSections({
        ...includedSections,
        [key]: { ...includedSections[key], checked: !includedSections[key].checked }
      });

      setIncludedSectionsCount(includedSectionsCount + 1);
    }
  };

  const generateReport = () => {
    const { from, to } = props.intervalDate;
    const startDateTimeUTC = convertLocalTimeToUTC(from, timezone);
    const endDateTimeUTC = convertLocalTimeToUTC(to, timezone);
    const sections = {} as { [key in INCLUDED_REPORT_SECTIONS]: boolean };

    for (const section in includedSections) {
      const typedSection = section as INCLUDED_REPORT_SECTIONS;
      if (includedSections[typedSection].checked && !includedSections[typedSection].disabled) {
        sections[typedSection] = true;
      }
    }

    generateReportAPI({
      body: {
        startDateTime: startDateTimeUTC,
        endDateTime: endDateTimeUTC,
        nodes: props.selectedLocationsSKs,
        format: formatType,
        reportTitle: title,
        tz: timezone,
        emailNotification: notifyByEmail,
        ...sections,
        ...(sections[INCLUDED_REPORT_SECTIONS.DATA_TABLE] ? { dataTableInterval: Number(dataTableInterval) * 60 } : {})
      },
      customerId
    })
      .unwrap()
      .then(_data => {
        props.showConfirmation();
      })
      .catch(e => {
        // TODO: Future handling of error cases
        console.error(e);
      });
  };

  const renderSection = (key: INCLUDED_REPORT_SECTIONS) => (
    <React.Fragment key={key}>
      <Checkbox
        dataTa={key}
        id="included-checkbox"
        checked={includedSections[key].checked}
        disabled={includedSections[key].disabled}
        label={includedSections[key].label}
        onChange={() => toggleIncludedSection(key)}
      />

      {key === INCLUDED_REPORT_SECTIONS.DATA_TABLE && (
        <Select
          id="datatable-interval"
          width={Size.L}
          value={dataTableInterval}
          onSelect={(value: string) => setDataTableInterval(value)}
          disabled={!includedSections[key].checked}
        >
          {intervalValues.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      )}
    </React.Fragment>
  );

  const toggleAboutContent = () => setShowAboutContent(!showAboutContent);

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTitle(value);

    if (value.length > MAX_TITLE_CHARACTERS) {
      setTitleError(t('reports.maxTitleError', { max: MAX_TITLE_CHARACTERS }));
    } else {
      setTitleError('');
    }
  };

  /**
   * Sets Report generation to disabled if the page is loading or PDF type page count is has more than allowed.
   * @returns {boolean} - If the form is not filled corretly, then return true for disabled generate button
   */
  const reportGenerationDisabled = (): boolean => {
    // sanity check for title and format type
    if (!title || titleError || !formatType) {
      return true;
    }
    // only restrict max pages for PDF format
    if (formatType === REPORT_FORMATS.PDF && estimatedPagesCount > MAX_ESTIMATED_PAGES_ALLOWED) {
      return true;
    }
    // waiting for data
    if (isLoading) {
      return true;
    }
    return false;
  };

  return (
    <div data-ta={TEST_IDS.generate_report_dialog_id}>
      <Modal
        id="generate-report-dialog-id"
        htmlId="generate-report-dialog-id"
        isOpen={props.isVisible}
        showCloseIcon={true}
        onDismiss={props.onDismiss}
        width={Size.L}
        dismissOnOverlayClick={false}
      >
        <Heading className="vai-margin-top-none generate-report-header">{t('reports.generateReport')}</Heading>

        <Grid className="main-grid-wrapper">
          <Grid.Row className="row">
            <Grid.Col md={7} lg={7} xl={7} className="left-column">
              <div>
                <span className="col-title">
                  {props.selectedFormattedLocationsNum} <span className="capitalize">{t('reports.locations')}</span>
                </span>

                <Form className="report-form">
                  <Form.Item
                    id="report-title"
                    component={InputField}
                    label={t('reports.title')}
                    labelWidth={Size.S}
                    required
                    width={Size.L}
                    value={title}
                    errors={titleError}
                    onChange={onTitleChange}
                  />

                  <Form.Item required label="Format" labelWidth={Size.S}>
                    <Select
                      id="reports-format"
                      dataTa={TEST_IDS.generate_report_format_select}
                      placeholder={t('reports.selectPlaceholder')}
                      width={Size.L}
                      onSelect={(value: string) => setFormatType(value)}
                    >
                      <Select.Option value={REPORT_FORMATS.PDF}>PDF</Select.Option>
                      <Select.Option value={REPORT_FORMATS.XLSX}>XLSX</Select.Option>
                      <Select.Option value={REPORT_FORMATS.CSV}>CSV</Select.Option>
                    </Select>
                  </Form.Item>

                  <div className="date-range-wrapper">
                    <span className="date-range-title">{t('reports.dateRange')}</span>
                    <Flex className="date-range">
                      <span>{format(props.intervalDate.from, 'yyyy/MM/dd HH:mm:ss')}</span>

                      <Icon size={Size.M} name={VaiIcon.ArrowRight} className="date-separator" />

                      <span>{format(props.intervalDate.to, 'yyyy/MM/dd HH:mm:ss')}</span>
                    </Flex>
                  </div>
                </Form>
              </div>

              <div className="notify-checkbox-wrapper inverted">
                <Checkbox
                  id="notify-checkbox"
                  checked={notifyByEmail}
                  label={t('reports.notifyWhenReportReady')}
                  disabled={true}
                />
              </div>
            </Grid.Col>

            <Grid.Col md={5} lg={5} xl={5} className="right-column">
              <div>
                <div className="col-title vai-margin-bottom-m">{t('reports.include')}</div>

                <Flex flexDirection="column" className="included-checkbox-wrapper">
                  {Object.keys(includedSections).map(key => renderSection(key as INCLUDED_REPORT_SECTIONS))}
                </Flex>
              </div>

              {formatType === REPORT_FORMATS.PDF && (
                <>
                  <Flex className="vai-margin-top-s" justifyContent="space-between">
                    <Icon
                      data-ta={TEST_IDS.generate_report_warning_message_icon}
                      className="vai-padding-top-s vai-padding-right-s"
                      name={VaiIcon.Warning}
                      color={VaiColor.Orange}
                      size={Size.M}
                    />
                    <span data-value={estimatedPagesCount} data-ta={TEST_IDS.resports_estimated_page_count}>
                      {t('reports.estimatedPageCount')}
                    </span>
                  </Flex>
                  {estimatedPagesCount > MAX_ESTIMATED_PAGES_ALLOWED && (
                    <Flex className="vai-margin-top-s" data-ta={TEST_IDS.generate_report_error_message}>
                      <Icon
                        data-ta={TEST_IDS.generate_report_error_message_icon}
                        className="vai-padding-top-s vai-padding-right-s"
                        name={VaiIcon.AlertAlarm}
                        color={VaiColor.Red}
                        size={Size.M}
                      />
                      <span>{t('reports.pageCountMaximumErrorMessage')}</span>
                    </Flex>
                  )}
                </>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>

        <Flex className="vai-margin-top-m generate-report-bottom-bar" justifyContent="center" alignItems="center">
          <div className="about-toggle" onClick={toggleAboutContent}>
            <Icon name={VaiIcon.HelpO} color={VaiColor.AquaVaisala} size={Size.M} />
            <span>{t('reports.aboutReporting')}</span>
          </div>
          <Button
            id="generate-report-confirm"
            dataTa={TEST_IDS.generate_report_confirm}
            disabled={reportGenerationDisabled()}
            htmlId="generate-report-confirm"
            onClick={generateReport}
          >
            {isLoading ? <CenteredSpinner className="" /> : t('reports.generate')}
          </Button>
          <Button
            id="generate-report-cancel"
            htmlId="generate-report-cancel"
            onClick={props.onDismiss}
            buttonType={ButtonType.Secondary}
          >
            {t('general.cancel')}
          </Button>
        </Flex>

        {showAboutContent && (
          <div className="about-content">
            <span className="main-title">{t('reports.whatIsIncluded')}:</span>

            {aboutContent.map(content => (
              <Flex flexDirection="column" className="about-section" key={content.title}>
                <span className="sub-title">{content.title}</span>
                <span>{content.description}</span>
              </Flex>
            ))}
          </div>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ reports }: StoreState) => ({
  selectedFormattedLocations: reports.selectedFormattedLocations,
  selectedFormattedLocationsNum: reports.selectedLocationsNum,
  graphsNum: reports.graphsNum,
  intervalDate: reports.intervalDate,
  selectedLocationsIDs: reports.selectedLocationsIDs,
  selectedLocationsSKs: reports.selectedLocationsSKs
});

export default connect(mapStateToProps)(GenerateReportDialog);
