import { isEmpty, cloneDeep } from 'lodash';
import { ADMIN_RIGHTS_MASK, RIGHTS_MASK } from '../../rightsConstants';
import { LocationNode, SiteNode, ZoneNode } from '../../siteTree/site';
import { addRightToBit, removeRightFromBit, isLocationNode } from '../../utils';
import {
  CheckboxRightsState,
  ZONE_RIGHTS_LIST,
  CHECKBOX_STATE_COMBINATIONS,
  DEFAULT_RIGHTS_BY_TYPE,
  RightNames,
  GlobalRightNames,
  GLOBAL_RIGHTS_LIST,
  SiteRightsTree,
  NO_RIGHTS
} from './constants';
import { CheckboxState } from '../MultiStateCheckbox/constants';
import { SiteNodeTypes, WILDCARD } from '../../constants';

/**
 * It will convert admin rights with '*' to rights with node_id
 * @param rights - Admin rights object with '*'
 * @param node - Site
 * @returns
 */
export const convertToRightsWithNodeIds = (rights: Record<string, number>, site: SiteNode) => {
  if (!rights[WILDCARD]) {
    throw new Error('No admin rights found');
  }
  const ids = getNodeChildrenIds(convertSiteTreeToRightsTree(site));
  const convertedRights = { [site.node_id]: ADMIN_RIGHTS_MASK };
  ids.forEach(id => {
    convertedRights[id] = ADMIN_RIGHTS_MASK;
  });
  return convertedRights;
};

/**
 * It will add missing node ids to the rights object and assign no rights mask to them.
 * This provides type safety for right value when checkbox states are calculated.
 * @param rights - Rights object that contains rights for all nodes
 * @param node - Site or SiteChildren
 * @returns
 */

export const normalizeRights = (rights: Record<string, number>, node: SiteNode | ZoneNode | LocationNode) => {
  const normalizedRights = { ...rights };
  if (rights[node.node_id] === undefined && !isLocationNode(node)) {
    normalizedRights[node.node_id] = NO_RIGHTS;
  } else {
    !isLocationNode(node) &&
      node.children.forEach(child => {
        if (!isLocationNode(child)) {
          normalizeRights(normalizedRights, child);
        }
      });
  }
  return normalizedRights;
};

/**
 * When parent has View right whereveer in its children it should have VIEW_NON_INHERITABLE mask.
 * We are using CheckboxState.UNCHECKED_INVERTED to identify nodes that will have VIEW_NON_INHERITABLE mask.
 * @param node - SiteRightsTree
 * @param bit - rights object
 * @returns rights object with VIEW_NON_INHERITABLE mask applied
 */

export const applyInheritence = (node: SiteRightsTree, rights: Record<string, number>) => {
  if (node.checkboxRightsStates.VIEW === CheckboxState.UNCHECKED_INVERTED) {
    rights[node.node_id] = addRightToBit(rights[node.node_id], RIGHTS_MASK.VIEW_NON_INHERITABLE);
  } else {
    rights[node.node_id] = removeRightFromBit(rights[node.node_id], RIGHTS_MASK.VIEW_NON_INHERITABLE);
  }

  node.children?.forEach(child => {
    applyInheritence(child, rights);
  });

  return rights;
};

/**
 * Toggle right mask in a bit
 * @param value - boolean value to determine if right should be added or removed
 * @param bit - bit mask to apply right to
 * @param rightName - name of the right mask to use
 * @returns
 */

export function toggleRightInBit(value: boolean, bit: number, rightName: string): number {
  const REMOVE_RIGHTS_MASK: { [key: string]: number } = {
    ...RIGHTS_MASK,
    [RightNames.VIEW]:
      RIGHTS_MASK[RightNames.VIEW] |
      RIGHTS_MASK[RightNames.ACKNOWLEDGE_ALARMS] |
      RIGHTS_MASK[RightNames.PAUSE_ALARMS] |
      RIGHTS_MASK[RightNames.MANAGE_ALARM_TEMPLATES] |
      RIGHTS_MASK[RightNames.ACKNOWLEDGE_ALARMS]
  };

  const ADD_RIGHTS_MASK: { [key: string]: number } = {
    ...RIGHTS_MASK,
    [RightNames.ACKNOWLEDGE_ALARMS]: RIGHTS_MASK[RightNames.ACKNOWLEDGE_ALARMS] | RIGHTS_MASK[RightNames.VIEW],
    [RightNames.PAUSE_ALARMS]: RIGHTS_MASK[RightNames.PAUSE_ALARMS] | RIGHTS_MASK[RightNames.VIEW],
    [RightNames.MANAGE_ALARM_TEMPLATES]: RIGHTS_MASK[RightNames.MANAGE_ALARM_TEMPLATES] | RIGHTS_MASK[RightNames.VIEW]
  };

  return value
    ? addRightToBit(bit, ADD_RIGHTS_MASK[rightName])
    : removeRightFromBit(bit, REMOVE_RIGHTS_MASK[rightName]);
}
export const convertSiteTreeToRightsTree = (node: SiteNode | ZoneNode, parent?: SiteRightsTree) => {
  const rightTreeNode: SiteRightsTree = {
    ...cloneDeep(node),
    type: node.type,
    checkboxRightsStates: DEFAULT_RIGHTS_BY_TYPE[node.type],
    parent: cloneDeep(parent) || null,
    children: []
  };

  rightTreeNode.children = (node.children.filter(node => !isLocationNode(node)) as (ZoneNode | SiteNode)[]).map(
    child => {
      const node: SiteRightsTree = convertSiteTreeToRightsTree(child, rightTreeNode);
      return node;
    }
  );

  return rightTreeNode;
};

/**
 * Main function where checkbox right states gets updated. It takes bottom to top approach
 * applying right to the nodes and aggregating them to the parent node.
 * @param node - root node to start applying rights to
 * @param rights - rights object that contains rights for all nodes
 * @returns
 */

export const applyRightsToSite = (node: SiteRightsTree, rights: Record<string, number>): CheckboxRightsState => {
  // Derive aggregated rights from children individual rights
  const childrenRights = node.children.reduce<CheckboxRightsState | null>((acc, curr) => {
    const currentRights = applyRightsToSite(curr, rights);
    if (isEmpty(acc)) {
      return currentRights;
    }
    const newRights = { ...acc };
    ZONE_RIGHTS_LIST.forEach(key => {
      const typedKey = key as keyof CheckboxRightsState;
      if (currentRights[typedKey] !== newRights[typedKey]) {
        newRights[typedKey] = CHECKBOX_STATE_COMBINATIONS[currentRights[typedKey] + newRights[typedKey]];
      }
    });
    return newRights;
  }, null);

  node.checkboxRightsStates = convertBitMaskToCheckboxRightsState(rights[node.node_id], node.type);
  node.checkboxRightsStates = applyChildRights(node.checkboxRightsStates, childrenRights);

  return node.checkboxRightsStates;
};

/**
 * Will aggregate child rights with parent rights based on CHECKBOX_STATE_COMBINATIONS
 * @param parentRights - parent CheckboxRightsState
 * @param childRights - child CheckboxRightsState
 * @returns
 */

export function applyChildRights(
  parentRights: CheckboxRightsState,
  childRights: CheckboxRightsState | null
): CheckboxRightsState {
  const aggregatedParentRights = { ...parentRights };
  if (!isEmpty(childRights)) {
    for (const key in childRights) {
      const typedKey = key as keyof CheckboxRightsState;
      aggregatedParentRights[typedKey] = CHECKBOX_STATE_COMBINATIONS[parentRights[typedKey] + childRights[typedKey]];
    }
  }
  return aggregatedParentRights;
}

export const convertBitMaskToCheckboxRightsState = (
  bitMask: number,
  nodeType: Exclude<SiteNodeTypes, SiteNodeTypes.LOCATION>
): CheckboxRightsState => {
  if (bitMask === undefined) {
    return { ...DEFAULT_RIGHTS_BY_TYPE[nodeType] };
  }
  const checkboxRightsState = cloneDeep(DEFAULT_RIGHTS_BY_TYPE[nodeType]);
  Object.keys(checkboxRightsState).forEach(key => {
    const typedKey = key as keyof CheckboxRightsState;
    checkboxRightsState[typedKey] = bitHasMask(bitMask, RIGHTS_MASK[typedKey])
      ? CheckboxState.CHECKED
      : CheckboxState.UNCHECKED;
  });
  return checkboxRightsState;
};

/** Recursively get all children ids of a node
 * @param node - Site or SiteChildren
 */
export const getNodeChildrenIds = (node: SiteRightsTree) => {
  const childrenIds: string[] = [];
  if (node.children) {
    node.children.forEach(child => {
      childrenIds.push(child.node_id);
      childrenIds.push(...getNodeChildrenIds(child));
    });
  }
  return childrenIds;
};

/** Recursively get all children ids of a node
 * @param node - Site or SiteChildren
 */
export const getNodeParentIds = (node: SiteRightsTree) => {
  const parentIds: string[] = [];
  if (node.parent) {
    parentIds.push(node.parent.node_id);
    parentIds.push(...getNodeParentIds(node.parent));
  }
  return parentIds;
};

export const isGlobalRight = (rightName: RightNames): rightName is GlobalRightNames => {
  return GLOBAL_RIGHTS_LIST.includes(rightName);
};

export const bitHasMask = (bit: number, mask: number) => {
  return (bit & mask) === mask;
};
